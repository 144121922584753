import { graphql } from "gatsby";
import React, { useCallback, useLayoutEffect, useState } from "react";
import { ColorTheme } from "../../../data/enum/ColorTheme";
import { ContentstackSlide } from "../../../data/types/Contentstack";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { InfiniteSlider } from "../../content/slider/base/InfiniteSlider/InfiniteSlider";
import { InfiniteSliderDesktop } from "../../content/slider/base/InfiniteSliderDesktop/InfiniteSliderDesktop";
import DetailLine from "../../general/borders/DetailLine/DetailLine";
import Loader from "../../general/Loader/Loader";
import SectionTitle from "../../general/SectionTitle/SectionTitle";
import Volt from "../../general/Volt/Volt";
import styles from "./GameObjective.module.scss";

export interface GameObjectiveProps {
  titleParts: Array<string>;
  slides: Array<ContentstackSlide>;
}

export const GameObjective: React.FC<GameObjectiveProps> = ({ titleParts, slides }) => {
  const isMobile = useIsMobile();

  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [resizeTimer, setResizeTimer] = useState<ReturnType<typeof setTimeout> | null>(null);

  const handleResize = useCallback(() => {
    setIsResizing(true);
    resizeTimer && clearTimeout(resizeTimer);
    setResizeTimer(setTimeout(() => setIsResizing(false), 500));
  }, [resizeTimer]);

  useLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return (
    <section className={styles.gameOverview}>
      <div className={styles.gameOverviewContent}>
        <div className={`container ${styles.container}`}>
          <div className={`content-wrapper`}>
            <DetailLine className={styles.titleDetailWrapper} variation="top" theme={ColorTheme.LIGHT}>
              <SectionTitle className={"heading-08"} titleParts={titleParts} theme={ColorTheme.LIGHT} />
            </DetailLine>
          </div>
        </div>
        {isMobile ? (
          <InfiniteSlider className={styles.mobileSlider} slides={slides} />
        ) : isResizing ? (
          <div className={styles.loaderWrapper}>
            <Loader className={styles.loader} />
          </div>
        ) : (
          <InfiniteSliderDesktop slides={slides} />
        )}
      </div>
      <Volt className={`${styles.volt} ${styles.bottomVolt}`} name={"VoltBottom03"} position="bottom" />
    </section>
  );
};

export const query = graphql`
  fragment GameObjective on Contentstack_game_overview {
    gameObjective: objective {
      titleParts: title
      slides: carousel {
        title
        description
        image {
          ...ContentstackAsset
        }
      }
    }
  }
`;
