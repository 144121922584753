import { gsap } from "gsap";
import { DrawSVGPlugin } from "../../../../../vendor/gsap/DrawSVGPlugin.js";

gsap.registerPlugin(DrawSVGPlugin);

export const animateFrame = (iconRefElement: HTMLDivElement | null) => {
  if (!iconRefElement || !iconRefElement.firstElementChild) return;

  const elements = [...iconRefElement.firstElementChild.childNodes];

  const topVGMDecoration = elements[0];
  const bottomSVGMDecoration = elements[1];
  const borderSVGAsset = elements[2];

  const tl = gsap.timeline({ paused: true, defaults: { ease: "power1.inOut" } });
  tl.from(borderSVGAsset, { duration: 1, drawSVG: "0%" }, 0.5)
    .from(bottomSVGMDecoration, { duration: 0.25, autoAlpha: 0 }, "<+0.25")
    .from(topVGMDecoration, { duration: 0.25, autoAlpha: 0 }, "<+0.3");

  return tl;
};

export const animateArrows = (arrowsContainer: HTMLDivElement | null) => {
  if (!arrowsContainer) return;

  const tl = gsap.timeline({ paused: true });
  tl.fromTo(arrowsContainer, { opacity: 0 }, { opacity: 1, delay: 1 });

  return tl;
};
