import React, { FunctionComponent } from "react";
import { PageProps, graphql } from "gatsby";
import { ContentstackGameOverview } from "../data/types/ContentstackPages";
import { ImageHero } from "../components/block/ImageHero/ImageHero";
import { ChampionOverview } from "../components/block/ChampionOverview/ChampionOverview";
import { AbilitiesOverview } from "../components/block/AbilitiesOverview/AbilitiesOverview";
import { GameDetails } from "../components/block/GameDetails/GameDetails";
import { FeaturedContent } from "../components/block/FeaturedContent/FeaturedContent";
import { GameObjective } from "../components/block/GameObjective/GameObjective";
import styles from "../style/templates/GameOverview.module.scss";
import overviewData from '../json/overview.json';

interface GameOverviewProps {
  allContentstackGameOverview: { nodes: Array<ContentstackGameOverview> };
}

const GameOverview: FunctionComponent<PageProps<GameOverviewProps>> = ({
  // data: {
  //   allContentstackGameOverview: {
  //     nodes: [{ imageHero, gameObjective, championOverview, abilitiesOverview, gameDetails, featuredContentCards }],
  //   },
  // },
}) => {
  const {
    allContentstackGameOverview: {
      nodes: [{ imageHero, gameObjective, championOverview, abilitiesOverview, gameDetails, featuredContentCards }],
    },
  } = overviewData.result.data;

  return (
    <>
      <ImageHero titleParts={imageHero.titleParts} description={imageHero.description} image={imageHero.image} />
      <GameObjective titleParts={gameObjective.titleParts} slides={gameObjective.slides} />
      <ChampionOverview
        title={championOverview.title}
        description={championOverview.description}
        champions={championOverview.champions}
        backgroundImage={championOverview.backgroundImage}
        cta={championOverview.cta}
      />
      <div className={styles.sectionsWrapper}>
        <AbilitiesOverview
          titleParts={abilitiesOverview.titleParts}
          description={abilitiesOverview.description}
          abilities={abilitiesOverview.abilities}
        />
        <GameDetails titleParts={gameDetails.titleParts} detailList={gameDetails.detailList} />
      </div>
      <FeaturedContent featuredContentCards={featuredContentCards} />
    </>
  )
};

export const query = graphql`
  query GameOverviewQuery($language: String!) {
    allContentstackGameOverview(filter: { publish_details: { locale: { eq: $language } } }) {
      nodes {
        ...ImageHero
        ...GameObjective
        ...ChampionOverview
        ...AbilitiesOverview
        ...GameDetails
        ...FeaturedContent
      }
    }
    pageMetadata: pageMetaData {
      title
      description
      image_url
    }
  }
`;

export default GameOverview;
