import React, { FunctionComponent, useState } from "react";
import { graphql } from "gatsby";
import { ContentstackAbilityDetails } from "../../../data/types/Contentstack";
import SectionTitle from "../../general/SectionTitle/SectionTitle";
import { Thumbnail } from "../../general/Thumbnail/Thumbnail";
import { Video } from "../../general/Video/Video";
import DetailLine, { DetailLineProps } from "../../general/borders/DetailLine/DetailLine";
import Volt from "../../general/Volt/Volt";
import styles from "./AbilitiesOverview.module.scss";
import { ColorTheme } from "../../../data/enum/ColorTheme";
import { TwoSideBorder } from "../../general/borders/TwoSideBorder/TwoSideBorder";
import TitleCopyWrapper from "../../general/borders/TitleCopyWrapper/TitleCopyWrapper";

export interface AbilitiesOverviewProps {
  titleParts: Array<string>;
  description?: string;
  abilities: Array<ContentstackAbilityDetails>;
  detailLineVariation?: DetailLineProps["variation"];
  theme?: ColorTheme;
}

export const AbilitiesOverview: FunctionComponent<AbilitiesOverviewProps> = ({
  titleParts,
  description,
  abilities,
  detailLineVariation = "left",
  theme = ColorTheme.LIGHT,
}) => {
  const [activeAbilityIndex, setActiveAbilityIndex] = useState<number>(0);

  return (
    <section className={`container ${styles.abilitiesOverview}`}>
      <Volt className={styles.topVolt} name={"VoltTop01"} position="top" />
      <div className={styles.croppedCorner}>
        <span className={styles.sectionCroppedCorner} />
        <span className={styles.sectionDashDetail} />
      </div>
      <div className="content-wrapper">
        <TitleCopyWrapper className={styles.copyWrapper} theme={theme}>
          <DetailLine className={styles.detailLine} variation={detailLineVariation} theme={theme}>
            <SectionTitle className={`heading-08 ${styles.title}`} titleParts={titleParts} theme={theme} />
          </DetailLine>
          {description && <p className={`copy-01 ${styles.description}`}>{description}</p>}
        </TitleCopyWrapper>

        <div className={styles.abilityContentWrapper}>
          <div className={styles.videoWrapper}>
            <TwoSideBorder className={`${styles.videoBorder} ${styles.top}`} position="topRight" />
            <TwoSideBorder className={`${styles.videoBorder} ${styles.bottom}`} position="bottomLeft" />
            {abilities[activeAbilityIndex].videos[0].video.file.url ? (
              <Video
                key={abilities[activeAbilityIndex].title}
                className={styles.videoContainer}
                source={{
                  mp4Url: abilities[activeAbilityIndex].videos[0].video.file.url,
                  posterUrl: abilities[activeAbilityIndex].videos[0].posterImage.url,
                }}
              />
            ) : (
              <h3>No video for this skill</h3>
            )}
          </div>
          <div className={styles.abilitiesDetailsWrapper}>
            <ul className={styles.thumbnailsWrapper}>
              {abilities.map(({ title, thumbnail }, index) => (
                <Thumbnail
                  className={`${styles.thumbnail} ${activeAbilityIndex === index ? styles.isActive : ""}`}
                  key={title}
                  thumbnail={thumbnail}
                  index={index}
                  setActiveItem={setActiveAbilityIndex}
                />
              ))}
            </ul>
            <div className={styles.detailsWrapper} key={abilities[activeAbilityIndex].title}>
              <h3 className={`heading-04 ${styles.abilityName}`}>
                <span>{abilities[activeAbilityIndex].title}</span>
              </h3>
              <p className={`copy-03 ${styles.abilityDescription}`}>{abilities[activeAbilityIndex].description}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.croppedCorner}>
        <span className={styles.sectionCroppedCorner} />
        <span className={styles.sectionDashDetail} />
      </div>
    </section>
  );
};

export const query = graphql`
  fragment AbilitiesOverview on Contentstack_game_overview {
    abilitiesOverview: abilities {
      titleParts: title
      description
      abilities: carousel {
        title
        description
        thumbnail {
          ...ContentstackAsset
        }
        videos: video {
          ...ContentstackVideo
        }
      }
    }
  }
`;
