import React, { FunctionComponent } from "react";
import { graphql } from "gatsby";
import { ContentstackChampionDetails, ContentstackAsset, ContentstackCTA } from "../../../data/types/Contentstack";
import SectionTitle from "../../general/SectionTitle/SectionTitle";
import { ChampionSlider } from "../../content/slider/ChampionSlider/ChampionSlider";
import DetailLine from "../../general/borders/DetailLine/DetailLine";
import { ColorTheme } from "../../../data/enum/ColorTheme";
import styles from "./ChampionOverview.module.scss";
import TitleCopyWrapper from "../../general/borders/TitleCopyWrapper/TitleCopyWrapper";
import { useIsMobile } from "../../../hooks/useIsMobile";
import PrimaryButton from "../../general/button/PrimaryButton/PrimaryButton";

export interface ChampionOverviewProps {
  title: Array<string>;
  description: string;
  backgroundImage: ContentstackAsset;
  champions: Array<ContentstackChampionDetails>;
  cta: ContentstackCTA;
}

export const ChampionOverview: FunctionComponent<ChampionOverviewProps> = ({
  title: titleParts,
  description,
  backgroundImage,
  champions,
  cta,
}) => {
  const isMobile = useIsMobile();
  return (
    <section className={`container ${styles.championOverview}`}>
      <div className={`content-wrapper light ${styles.contentWrapper}`}>
        <div className={styles.content}>
          {isMobile && (
            <TitleCopyWrapper className={styles.titleDetailWrapper}>
              <DetailLine className={styles.detailLine} variation="left" theme={ColorTheme.DARK}>
                <SectionTitle className={styles.sectionTitle} titleParts={titleParts} theme={ColorTheme.DARK} />
              </DetailLine>
              <p className={`copy-01 ${styles.sectionDescription}`}>{description}</p>
            </TitleCopyWrapper>
          )}
          <ChampionSlider
            backgroundImage={backgroundImage}
            champions={champions}
            titleParts={titleParts}
            description={description}
          />
        </div>

        {/* <PrimaryButton
          className={styles.championCta}
          label={cta.title}
          to={cta.href}
          theme={ColorTheme.DARK}
          hasWhiteBackground={true}
        /> */}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment ChampionOverview on Contentstack_game_overview {
    championOverview: champions {
      title
      description
      backgroundImage: background_image {
        ...ContentstackAsset
      }
      champions: carousel {
        name: champion
        description
        skins {
          name
          thumbnail {
            ...ContentstackAsset
          }
          image {
            ...ContentstackAsset
          }
        }
      }
      cta {
        title
        href
      }
    }
  }
`;
