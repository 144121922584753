import React, { useMemo, useRef } from "react";
import { ContentstackSlide } from "../../../../../data/types/Contentstack";
import { useScrollObserver } from "../../../../../hooks/useScrollObserver";
import { useSlider } from "../../../../../hooks/useSlider";
import SecondaryButton from "../../../../general/button/SecondaryButton/SecondaryButton";
import Icon from "../../../../general/Icon/Icon";
import { animateArrows, animateFrame, animateBullets } from "./animations";
import styles from "./InfiniteSliderDesktop.module.scss";

interface InfiniteSliderDesktopProps {
  slides: Array<ContentstackSlide>;
}
export const InfiniteSliderDesktop: React.FC<InfiniteSliderDesktopProps> = ({ slides }) => {
  const duplicatedSlides = useMemo(() => [...slides, ...slides], [slides]);

  const {
    sliderRef,
    data: { activeSlideIndex },
    actions: { goToPrevious, goToNext },
  } = useSlider({
    loop: true,
  });

  const iconRef = useRef<HTMLDivElement | null>(null);
  const observerRef = useScrollObserver<HTMLDivElement | null>(animateFrame, iconRef, {
    triggerOnce: true,
  });
  const arrowsContainerRef = useRef<HTMLDivElement | null>(null);
  const arrowsObserverRef = useScrollObserver<HTMLDivElement | null>(animateArrows, arrowsContainerRef, {
    triggerOnce: true,
  });
  const bulletsWrapperRef = useRef<HTMLUListElement | null>(null);
  const bulletsObserverRef = useScrollObserver<HTMLUListElement | null>(animateBullets, bulletsWrapperRef, {
    triggerOnce: true,
  });

  return (
    <div className={styles.desktopSlider} ref={observerRef}>
      <div className={styles.sliderWrapper} ref={sliderRef}>
        <ul className={styles.slider}>
          {duplicatedSlides.map((slide, index) => (
            <li key={index} className={`copy-01 ${styles.slide} ${activeSlideIndex === index ? styles.isActive : ""}`}>
              {slide.image && <img src={slide.image?.url} alt={slide.image?.title} />}
              <div className={styles.copyWrapper}>
                {slide.title && (
                  <h3 className={`heading-04 ${styles.slideTitle}`}>
                    <span className={styles.index}>{`${(index % slides.length) + 1}`}</span>
                    <Icon className={styles.textDivider} name="Diamond" />
                    <span className={styles.titleCopy}>{`${slide.title}`}</span>
                  </h3>
                )}
                <p className={"copy-03"}>{slide.description}</p>
              </div>
            </li>
          ))}
        </ul>

        <div ref={arrowsObserverRef} className={styles.frameWrapper}>
          <div className={styles.sliderArrowsWrapper} ref={arrowsContainerRef}>
            <SecondaryButton
              className={`${styles.sliderArrow} ${styles.arrowPrev}`}
              onClick={goToPrevious}
              icon="LongArrow"
            />
            <SecondaryButton
              className={`${styles.sliderArrow} ${styles.arrowNext}`}
              onClick={goToNext}
              icon="LongArrow"
            />
          </div>
          <Icon className={styles.svgFrame} name="MobileSliderFrame" innerRef={iconRef} />
        </div>
      </div>
      <div ref={bulletsObserverRef}>
        <ul ref={bulletsWrapperRef} className={styles.bulletsWrapper}>
          {slides.map((_, index: number) => (
            <li
              key={index}
              className={`${styles.bulletWrapper} ${activeSlideIndex % slides.length === index ? styles.active : ""}`}
            >
              <Icon className={styles.bulletIcon} name="Diamond" />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
