import React, { FC, useRef, useEffect } from "react";
import { graphql } from "gatsby";
import { ContentstackFeaturedContent } from "../../../data/types/Contentstack";
import styles from "./FeaturedContent.module.scss";
import { animateCardList } from "./animations";
import { useScrollObserver } from "../../../hooks/useScrollObserver";

type FeaturedContentCardProps = {
  index: number;
  title: string;
  imageUrl: string;
  cardsRef: React.MutableRefObject<Array<HTMLDivElement | null>>;
};

const FeaturedContentCard: React.FC<FeaturedContentCardProps> = ({ index, title, imageUrl, cardsRef }) => {
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!cardRef.current || !cardRef.current) return;
    cardsRef.current[index] = cardRef.current;
  }, [index, cardsRef]);

  return (
    <div ref={cardRef} className={styles.featuredContentCard} key={`${title}-${index}`}>
      <span className={styles.cardBorder}>
        <span className={`${styles.borderLine} ${styles.left}`} />
        <span className={styles.dashDetail} />
        <span className={`${styles.borderLine} ${styles.bottom}`} />
      </span>
      <span className={styles.backgrounImage} style={{ backgroundImage: `url(${imageUrl})` }} />
      <h4 className={`heading-05 ${styles.title}`}>{title}</h4>
    </div>
  );
};

export interface FeaturedContentProps {
  featuredContentCards: Array<ContentstackFeaturedContent>;
}

export const FeaturedContent: FC<FeaturedContentProps> = ({ featuredContentCards }) => {
  const cardsRef = useRef<Array<HTMLDivElement>>([]);

  const observerRef = useScrollObserver<Array<HTMLDivElement>>(animateCardList, cardsRef, { triggerOnce: true });

  return (
    <section className={`container ${styles.featuredContent}`}>
      <span className={styles.sectionCroppedCorner} />
      <span className={styles.sectionDashDetail} />
      <span className={styles.background} />
      <div ref={observerRef} className={`content-wrapper dark ${styles.featuredContentWrapper}`}>
        {featuredContentCards.map(({ title, image: { url: imageUrl } }, index: number) => (
          <FeaturedContentCard key={title} title={title} imageUrl={imageUrl} index={index} cardsRef={cardsRef} />
        ))}
      </div>
    </section>
  );
};

export const query = graphql`
  fragment FeaturedContent on Contentstack_game_overview {
    featuredContentCards: featured_content {
      title
      image {
        ...ContentstackAsset
      }
    }
  }
`;
