import React, { FunctionComponent, useRef } from "react";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import styles from "./ChampionsCircle.module.scss";
import { animateCircle } from "./animations";
import { useScrollObserver } from "../../../../hooks/useScrollObserver";
export type ChampionCircleProps = {
  classNames?: string;
};

export const ChampionCirlcle: FunctionComponent<ChampionCircleProps> = ({ classNames = "" }) => {
  const isMobile = useIsMobile();

  const animatedCircleWrapperRef = useRef<HTMLDivElement | null>(null);

  const observerRef = useScrollObserver<HTMLDivElement | null>(animateCircle, animatedCircleWrapperRef, {
    threshold: 0,
  });

  return (
    <div ref={observerRef} className={`${styles.circleWrapper} ${classNames}`}>
      <div ref={animatedCircleWrapperRef} className={styles.circleContainer}>
        <svg className={styles.circle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 624.77 617.06">
          <defs>
            <mask id="mask">
              <circle
                cx="312.33"
                cy="308.53"
                r="309"
                fill="none"
                stroke="#fff"
                strokeWidth="12"
                strokeMiterlimit="10"
              />
            </mask>
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                mask="url(#mask)"
                className={styles.cls1}
                strokeWidth="2"
                d="M219.63 606.74A312.66 312.66 0 0113 218.92s5.79-4.86 5.76-4.87c-24.12 78.75-17.49 166 21 238.82 38.59 73 103.08 126.21 182 150.52l2.74.59s-4.19 2.39-4.87 2.76zM606.21 402.16l.95-2.93c23.76-78.92 16.48-161.86-22.12-234.84C546.54 91.57 481.47 38 402.82 13.61c.34-.18 4.42-2 5.07-2.38A312.29 312.29 0 01611 399.79z"
              />
              <circle className={styles.cls2} cx="312.33" cy="308.53" r="307.47" fill="none" />
            </g>
          </g>
        </svg>
        {isMobile && (
          <svg className={styles.circleDetail} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.99 89.22">
            <g data-name="Layer 2">
              <g data-name="Layer 1">
                <path className={styles.cls1} d="M12-.006l11.993 11.992L12 23.98.007 11.986z" />
                <path className={styles.cls2} fill="none" d="M12 12.19v77.03" />
              </g>
            </g>
          </svg>
        )}
      </div>
    </div>
  );
};
