import React, { useEffect, useRef, useCallback } from "react";
import styles from "./TitleCopyWrapper.module.scss";
import { useInView } from "react-intersection-observer";
import { gsap, Expo } from "gsap";
import { ColorTheme, ColorThemeOption } from "../../../../data/enum/ColorTheme";

export interface TitleCopyWrapperProps {
  className?: string;
  theme?: ColorThemeOption;
}

const TitleCopyWrapper: React.FunctionComponent<TitleCopyWrapperProps> = ({
  className,
  theme = ColorTheme.DARK,
  children,
}) => {
  const leftLineRef = useRef<HTMLSpanElement | null>(null);

  const [observerRef, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const animateLeftLine = useCallback((element: HTMLSpanElement | null) => {
    gsap.delayedCall(0.25, () => {
      const tl = gsap.timeline({ defaults: { duration: 2.5, ease: Expo.easeOut } });
      tl.set(element, { opacity: 1, transformOrigin: "top" }).from(element, { height: 0, clearProps: "height" });
    });
  }, []);

  useEffect(() => {
    if (inView) {
      if (!leftLineRef) return;
      animateLeftLine(leftLineRef?.current);
    }
  }, [inView, animateLeftLine]);

  return (
    <div ref={observerRef} className={`${styles.titleCopyWrapper} ${className}`}>
      <span ref={leftLineRef} className={`${styles.leftLine} ${styles[theme]}`} />
      {children}
    </div>
  );
};

export default TitleCopyWrapper;
