import { gsap } from "gsap";

export const animateBorder = (element: HTMLSpanElement | null) => {
  if (!element) return;
  const duration = 0.4;
  const tl = gsap.timeline({ paused: true, defaults: { duration } });
  const childs = [...element?.children];
  gsap.delayedCall(0.3, () => {
    tl.set(childs[1], { opacity: 0 })
      .from(childs[0], { height: 0, ease: "expo.out" })
      .to(childs[1], { opacity: 1, duration: 0.05 }, duration - 0.2)
      .from(childs[1], { width: 0, duration: duration * 0.8 }, duration - 0.2)
      .from(childs[2], { width: 0 }, duration - 0.2)
      .set(childs, { clearProps: "all" });
  });
  return tl;
};
