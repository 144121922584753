import { graphql } from "gatsby";
import React, { FunctionComponent } from "react";
import { ColorTheme } from "../../../data/enum/ColorTheme";
import { ContentstackAsset } from "../../../data/types/Contentstack";
import { useIsMobile } from "../../../hooks/useIsMobile";
import DetailLine from "../../general/borders/DetailLine/DetailLine";
import TitleCopyWrapper from "../../general/borders/TitleCopyWrapper/TitleCopyWrapper";
import SectionTitle from "../../general/SectionTitle/SectionTitle";
import styles from "./ImageHero.module.scss";

export interface ImageHeroProps {
  titleParts: Array<string>;
  description: string;
  image: ContentstackAsset;
}

export const ImageHero: FunctionComponent<ImageHeroProps> = ({ titleParts, description, image }) => {
  const isMobile = useIsMobile();

  return (
    <section className={styles.imageHero}>
      <div className={`container ${styles.descriptionWrapper}`} style={{ backgroundImage: `url(${image.url})` }}>
        <div className={`content-wrapper dark ${styles.contentWrapper}`}>
          <TitleCopyWrapper className={styles.copyWrapper} theme={ColorTheme.LIGHT}>
            <DetailLine className={styles.detailLine} variation="left" theme={ColorTheme.LIGHT}>
              <SectionTitle className={styles.title} titleParts={titleParts} theme={ColorTheme.LIGHT} />
            </DetailLine>
            {!isMobile && <p className={`copy-01 ${styles.description}`}>{description}</p>}
          </TitleCopyWrapper>
        </div>
      </div>
      {isMobile && (
        <div className={`container ${styles.mobileDescriptionWrapper}`}>
          <div className="content-wrapper light">
            <p className={`copy-01 ${styles.description}`}>{description}</p>
          </div>
        </div>
      )}
    </section>
  );
};

export const query = graphql`
  fragment ImageHero on Contentstack_game_overview {
    imageHero: header {
      titleParts: title
      description
      image {
        ...ContentstackAsset
      }
    }
  }
`;
