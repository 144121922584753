import { gsap, Expo, Power1 } from "gsap";
import DrawSVGPlugin from "../../../../vendor/gsap/DrawSVGPlugin";

export const animateCircle = (container: HTMLDivElement | null) => {
  gsap.registerPlugin(DrawSVGPlugin);
  const animatedCircle = container?.firstChild;
  if (!animatedCircle || !container) return;

  const maskElement = animatedCircle?.firstChild?.firstChild?.firstChild as SVGPathElement;
  const circleDetailElement = animatedCircle?.lastChild?.firstChild?.firstChild as SVGPathElement;
  const circleElement = animatedCircle?.lastChild?.firstChild?.lastChild as SVGCircleElement;

  const tl = gsap.timeline({ paused: true });
  const rotationTL = gsap.timeline();
  rotationTL.to(circleDetailElement, { rotation: "+=360", duration: 6, repeat: -1, ease: "none" });
  tl.set(maskElement, { rotation: 45, drawSVG: "0%", transformOrigin: "center" })
    .set(circleDetailElement, { rotation: 90, transformOrigin: "center" })
    .set(circleElement, { rotation: -90, transformOrigin: "center" })
    .from(circleElement, { drawSVG: "0%", duration: 2, ease: Expo.easeInOut }, 0)
    .to(
      maskElement,
      { drawSVG: "100%", duration: 0.7, ease: Power1.easeInOut, onComplete: () => tl.timeScale(0.5) },
      "-=0.35",
    )
    .add(rotationTL, 0.6);

  return tl;
};
