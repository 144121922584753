import { gsap } from "gsap";
import { DrawSVGPlugin } from "../../../../../vendor/gsap/DrawSVGPlugin.js";

gsap.registerPlugin(DrawSVGPlugin);

export const animateFrame = (iconRefElement: HTMLDivElement | null) => {
  if (!iconRefElement || !iconRefElement.firstElementChild) return;

  const elements = [...iconRefElement.firstElementChild.childNodes];

  const topVGMDecoration = elements[0];
  const bottomSVGMDecoration = elements[1];
  const borderSVGAsset = elements[2];

  const tl = gsap.timeline({ paused: true, defaults: { ease: "power1.inOut" } });
  tl.from(borderSVGAsset, { duration: 1, drawSVG: "0%" }, 0.5)
    .from(bottomSVGMDecoration, { duration: 0.25, autoAlpha: 0 }, "<+0.25")
    .from(topVGMDecoration, { duration: 0.25, autoAlpha: 0 }, "<+0.3");

  return tl;
};

export const animateArrows = (arrowsContainer: HTMLDivElement | null) => {
  if (!arrowsContainer) return;

  const tl = gsap.timeline({ paused: true });
  tl.fromTo(arrowsContainer, { opacity: 0 }, { opacity: 1, delay: 1 });

  return tl;
};

export const animateBullets = (bulletsContainer: HTMLUListElement | null) => {
  if (!bulletsContainer) return;

  const duration = 0.2;
  const tl = gsap.timeline({ paused: true });
  const bulletsCount = bulletsContainer.childNodes.length;
  tl.from(bulletsContainer.childNodes, {
    autoAlpha: 0,
    xPercent: -100,
    stagger: 0.2,
    duration: (duration * bulletsCount) / 2,
    ease: "expo.out",
    delay: 0.8,
  }).set(bulletsContainer.childNodes, { clearProps: "opacity" });

  return tl;
};
