import React, { useCallback } from "react";
import { ContentstackAsset } from "../../../data/types/Contentstack";
import Icon from "../Icon/Icon";

export type ThumbnailProps = {
  thumbnail: ContentstackAsset;
  index: number;
  setActiveItem: React.Dispatch<React.SetStateAction<number>>;
  caption?: string;
  className?: string;
  iconRef?: React.MutableRefObject<HTMLDivElement | null>;
  isSkinsSection?: boolean;
};

export const Thumbnail: React.FC<ThumbnailProps> = ({
  thumbnail,
  caption,
  index,
  setActiveItem,
  className = "",
  isSkinsSection,
}) => {
  const handleClick = useCallback(() => {
    setActiveItem(index);
  }, [index, setActiveItem]);
  return (
    <li className={className} onClick={handleClick}>
      <span data-element="thumbnail-icon-wrapper">
        {isSkinsSection && <Icon name="ThumbnailCircle" />}
        {thumbnail?.url && <img src={thumbnail.url} alt={caption ? caption : "thumbnail"} />}
      </span>
      {caption && (
        <h5 className={`heading-05`} data-element="thumbnail-copy">
          {caption}
        </h5>
      )}
    </li>
  );
};
