import React, { useRef } from "react";
import { ContentstackSlide } from "../../../../../data/types/Contentstack";
import styles from "./InfiniteSlider.module.scss";
import Icon from "../../../../general/Icon/Icon";
import SecondaryButton from "../../../../general/button/SecondaryButton/SecondaryButton";
import { useSlider } from "../../../../../hooks/useSlider";
import { animateFrame, animateArrows } from "./animations";
import { useScrollObserver } from "../../../../../hooks/useScrollObserver";

interface InfiniteSliderProps {
  slides: Array<ContentstackSlide>;
  className?: string;
}

export const InfiniteSlider: React.FC<InfiniteSliderProps> = ({ slides, className = "" }) => {
  const {
    sliderRef,
    actions: { goToPrevious, goToNext },
    data: { activeSlideIndex, scrollSnaps },
  } = useSlider({ loop: true });
  const iconRef = useRef<HTMLDivElement | null>(null);
  const arrowsContainerRef = useRef<HTMLDivElement | null>(null);

  const observerRef = useScrollObserver<HTMLDivElement | null>(animateFrame, iconRef, { triggerOnce: true });
  const arrowsObserverRef = useScrollObserver<HTMLDivElement | null>(animateArrows, arrowsContainerRef, {
    triggerOnce: true,
  });

  return (
    <div ref={observerRef} className={`${styles.slider} ${className}`}>
      <div className={`content-wrapper ${styles.carouselWrapper}`} ref={sliderRef}>
        <ul className={styles.carouselContent}>
          {slides.map((slide, index: number) => (
            <li key={index} className={`copy-01 ${styles.slide}`}>
              <div className={styles.square}>
                {slide.image && <img src={slide.image?.url} alt="slider image" className={styles.slideImage} />}
              </div>
              <div className={styles.copyWrapper}>
                {slide.title && (
                  <h3 className={`heading-04 ${styles.slideTitle}`}>
                    <span className={styles.index}>{`${index + 1}`}</span>
                    <Icon className={styles.textDivider} name="Diamond" />
                    <span className={styles.titleCopy}>{`${slide.title}`}</span>
                  </h3>
                )}
                <p className="copy-03">{slide.description}</p>
              </div>
            </li>
          ))}
        </ul>
        <div ref={arrowsObserverRef} className={`${styles.sliderArrows}`}>
          <div ref={arrowsContainerRef} className={styles.sliderArrowsWrapper} data-element="infinite-slider-arrows">
            <SecondaryButton
              className={`${styles.sliderArrow} ${styles.arrowPrev}`}
              onClick={goToPrevious}
              icon="LongArrow"
              ariaLabel="previous"
            />
            <SecondaryButton
              className={`${styles.sliderArrow} ${styles.arrowNext}`}
              onClick={goToNext}
              icon="LongArrow"
              ariaLabel="next"
            />
          </div>
        </div>
        <Icon innerRef={iconRef} className={styles.svgFrame} name="MobileSliderFrame" />
      </div>
      <ul className={styles.bulletsWrapper}>
        {scrollSnaps.map((_, index: number) => (
          <li key={index} className={`${styles.bulletWrapper} ${activeSlideIndex === index ? styles.active : ""}`}>
            <Icon className={styles.bulletIcon} name="Diamond" />
          </li>
        ))}
      </ul>
    </div>
  );
};
