import { graphql } from "gatsby";
import React, { FC, useState } from "react";
import { ColorTheme } from "../../../data/enum/ColorTheme";
import { ContentstackGameDetailData } from "../../../data/types/Contentstack";
import { useIsMobile } from "../../../hooks/useIsMobile";
import DetailLine from "../../general/borders/DetailLine/DetailLine";
import SectionTitle from "../../general/SectionTitle/SectionTitle";
import { Thumbnail } from "../../general/Thumbnail/Thumbnail";
import styles from "./GameDetails.module.scss";

export interface GameDetailsProps {
  titleParts: Array<string>;
  detailList: Array<ContentstackGameDetailData>;
}

export const GameDetails: FC<GameDetailsProps> = ({ titleParts, detailList }) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const isMobile = useIsMobile();

  return (
    <section className={styles.gameDetails}>
      <div className={isMobile ? `container ${styles.translateTitle}` : ""}>
        <div className={`content-wrapper ${styles.contentWrapper}`}>
          <DetailLine className={styles.titleWrapper} theme={ColorTheme.LIGHT} variation="top">
            <SectionTitle className={`${styles.title} heading-08`} theme={ColorTheme.LIGHT} titleParts={titleParts} />
          </DetailLine>
        </div>
      </div>
      <div className={`content-wrapper ${styles.contentWrapper}`}>
        <div className={styles.imageWrapper}>
          <img src={detailList[activeSlide].image.url} alt="featured champion" />
        </div>
        <div className={styles.detailsWrapper}>
          <ul className={styles.thumbnailWrapper}>
            {detailList.map((detail, index) => (
              <Thumbnail
                key={detail.title}
                caption={detail.title}
                thumbnail={detail.thumbnail}
                index={index}
                className={`${activeSlide === index ? styles.active : ""} ${styles.thumbnailItem}`}
                setActiveItem={setActiveSlide}
              />
            ))}
          </ul>
          <div className={styles.detailsCopyWrapper}>
            <p className={`copy-01 ${styles.detailsCopy}`}>{detailList[activeSlide].description}</p>
            {!isMobile && <span className={styles.dashDetail} />}
          </div>
        </div>
      </div>
    </section>
  );
};

export const query = graphql`
  fragment GameDetails on Contentstack_game_overview {
    gameDetails: power {
      titleParts: title
      detailList: carousel {
        title
        description
        thumbnail {
          ...ContentstackAsset
        }
        image {
          ...ContentstackAsset
        }
      }
    }
  }
`;
