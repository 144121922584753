import { gsap, Power1 } from "gsap";
export const animateCard = (card: HTMLDivElement) => {
  const borderElement = card.firstElementChild as HTMLSpanElement;
  const leftLineElement = borderElement.childNodes[0];
  const dashElement = borderElement.childNodes[1];
  const bottomLineElement = borderElement.childNodes[2];
  const titleElement = card.lastElementChild;
  const duration = 0.3;

  const tl = gsap.timeline({
    defaults: { duration, ease: Power1.easeOut },
  });

  tl.to(borderElement, { autoAlpha: 1, duration })
    .from(leftLineElement, { scaleY: 0 })
    .from(bottomLineElement, { scaleX: 0, duration: duration * 1.5 })
    .from(dashElement, { scaleX: 0 }, duration * 1.5)
    .fromTo(titleElement, { autoAlpha: 0, y: 30 }, { autoAlpha: 1, y: 0, duration });
};

export const animateCardList = (elements: Array<HTMLDivElement>) => {
  const tl = gsap.timeline({ paused: true });

  elements.forEach((card, index) =>
    tl.add(
      gsap.to(card, {
        y: 0,
        autoAlpha: 1,
        onStart: () => animateCard(card),
      }),
      0.4 * index,
    ),
  );

  return tl;
};
