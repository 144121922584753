import React, { FunctionComponent, useCallback, useRef } from "react";
import styles from "./TwoSideBorder.module.scss";
import { ColorTheme, ColorThemeOption } from "../../../../data/enum/ColorTheme";
import { useScrollObserver } from "../../../../hooks/useScrollObserver";
import { animateBorder } from "./animations";
export interface TwoSideBorderProps {
  position: "topRight" | "topLeft" | "bottomLeft" | "bottomRight";
  theme?: ColorThemeOption;
  className?: string;
}

export const TwoSideBorder: FunctionComponent<TwoSideBorderProps> = ({
  position,
  theme = ColorTheme.LIGHT,
  className = "",
}) => {
  const borderRef = useRef<HTMLSpanElement | null>(null);

  const observerRef = useScrollObserver<HTMLSpanElement | null>(animateBorder, borderRef, {
    threshold: 0.6,
    triggerOnce: true,
  });

  const setRefs = useCallback(
    (node) => {
      observerRef(node);
      borderRef.current = node;
    },
    [observerRef],
  );

  return (
    <span className={`${styles.border} ${styles[position]} ${styles[theme]} ${className}`} ref={setRefs}>
      <span className={`${styles.borderLine} ${styles.firstSide}`} />
      <span className={styles.dashDetail} />
      <span className={`${styles.borderLine} ${styles.secondSide}`} />
    </span>
  );
};
