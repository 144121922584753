import { Expo, gsap, Power1 } from "gsap";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ColorTheme } from "../../../../data/enum/ColorTheme";
import { ContentstackAsset, ContentstackChampionDetails } from "../../../../data/types/Contentstack";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { ChampionCirlcle } from "../../../general/borders/ChampionsCircle/ChampionsCircle";
import DetailLine from "../../../general/borders/DetailLine/DetailLine";
import TitleCopyWrapper from "../../../general/borders/TitleCopyWrapper/TitleCopyWrapper";
import SecondaryButton from "../../../general/button/SecondaryButton/SecondaryButton";
import Icon from "../../../general/Icon/Icon";
import SectionTitle from "../../../general/SectionTitle/SectionTitle";
import { Thumbnail } from "../../../general/Thumbnail/Thumbnail";
import styles from "./ChampionSlider.module.scss";

export interface ChampionSliderProps {
  backgroundImage: ContentstackAsset;
  champions: Array<ContentstackChampionDetails>;
  titleParts: Array<string>;
  description: string;
}

export const ChampionSlider: React.FC<ChampionSliderProps> = ({
  backgroundImage: { url: bgImageUrl },
  champions,
  titleParts,
  description,
}) => {
  const isMobile = useIsMobile();
  const [activeChampionIndex, setActiveChampionIndex] = useState<number>(0);
  const [activeSkinIndex, setActiveSkinIndex] = useState<number>(0);

  const selectedChampion = useMemo(() => champions[activeChampionIndex], [champions, activeChampionIndex]);
  const selectedSkin = useMemo(() => selectedChampion.skins[activeSkinIndex], [selectedChampion, activeSkinIndex]);

  const skinsWrapperRef = useRef<HTMLUListElement | null>(null);
  const animationWrapper = useRef<HTMLDivElement | null>(null);
  const thumbnailsWrapperRef = useRef<HTMLUListElement | null>(null);

  const goToNext = useCallback(() => {
    setActiveSkinIndex(0);
    setActiveChampionIndex(activeChampionIndex === champions.length - 1 ? 0 : activeChampionIndex + 1);
  }, [activeChampionIndex, champions.length]);

  const goToPrevious = useCallback(() => {
    setActiveSkinIndex(0);
    setActiveChampionIndex(activeChampionIndex === 0 ? champions.length - 1 : activeChampionIndex - 1);
  }, [activeChampionIndex, champions.length]);

  const transitionInChampion = () => {
    if (!animationWrapper.current || !skinsWrapperRef.current) return;

    gsap.fromTo(
      animationWrapper.current,
      { autoAlpha: 0, x: 20 },
      { autoAlpha: 1, x: 0, delay: 0.25, duration: 1.5, ease: Expo.easeOut },
    );

    gsap.to(skinsWrapperRef.current.children[0], { autoAlpha: 1, delay: 0.25, duration: 0.4, ease: Power1.easeOut });
  };

  const transitionInThumbnails = () => {
    if (!thumbnailsWrapperRef.current) return;

    gsap.set(thumbnailsWrapperRef.current, { autoAlpha: 1 });

    gsap.fromTo(
      thumbnailsWrapperRef.current?.children,
      { yPercent: 10, autoAlpha: 0 },
      { yPercent: 0, stagger: 0.15, autoAlpha: 1, duration: 1, delay: 0.05, ease: Power1.easeOut },
    );
  };

  const transitionSkin = (index: number, transitionIn: boolean) => {
    if (!skinsWrapperRef.current || skinsWrapperRef.current.children.length === 1) return;

    gsap.fromTo(
      skinsWrapperRef.current.children[index],
      { autoAlpha: transitionIn ? 0 : 1 },
      { autoAlpha: transitionIn ? 1 : 0, duration: 0.4, ease: Power1.easeOut },
    );
  };

  useEffect(() => {
    transitionSkin(activeSkinIndex, true);
    return () => transitionSkin(activeSkinIndex, false);
  }, [activeSkinIndex]);

  useEffect(() => {
    transitionInThumbnails();
    transitionInChampion();
  }, [activeChampionIndex]);

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.championWrapper}>
        <span className={styles.championBackgroundWrapper}>
          <img className={styles.championBackground} alt="champion background" src={bgImageUrl} />
        </span>
        <ChampionCirlcle classNames={styles.backgroundCircle} />
        <div ref={animationWrapper} className={styles.animationWrapper}>
          <ul ref={skinsWrapperRef} key={selectedChampion.name} className={styles.championImageWrapper}>
            {selectedChampion.skins.map((skin) => (
              <li key={skin.name} className={styles.imageWrapper}>
                <img className={styles.championImage} src={skin.image.url} alt={skin.image.title} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.championDataWrapper}>
        {!isMobile && (
          <TitleCopyWrapper className={styles.titleDetailWrapper}>
            <DetailLine className={styles.detailLine} variation="left" theme={ColorTheme.DARK}>
              <SectionTitle className={styles.sectionTitle} titleParts={titleParts} theme={ColorTheme.DARK} />
            </DetailLine>
            <p className={`copy-01 ${styles.sectionDescription}`}>{description}</p>
          </TitleCopyWrapper>
        )}
        <div className={styles.detailsWrapper}>
          <div className={styles.controlsWrapper}>
            <div className={styles.championNameWrapper}>
              {selectedChampion && <h3 className={`heading-02 ${styles.championName}`}>{selectedChampion.name}</h3>}
              {selectedSkin && <h4 className={`heading-09 font-normal ${styles.skinName}`}>{selectedSkin.name}</h4>}
            </div>
            <div className={styles.sliderControlsWrapper}>
              <SecondaryButton
                className={`${styles.sliderButton} ${styles.prevButton}`}
                onClick={goToPrevious}
                icon="Chevron"
                ariaLabel="previous"
              />
              <Icon className={styles.ellipseIcon} name="Ellipse" />
              <SecondaryButton
                className={`${styles.sliderButton} ${styles.nextButton}`}
                onClick={goToNext}
                icon="Chevron"
                ariaLabel="next"
              />
            </div>
          </div>
          <div className={styles.championInfoWrapper}>
            {selectedChampion.skins.length > 1 && (
              <ul ref={thumbnailsWrapperRef} className={styles.thumbnailsWrapper}>
                {selectedChampion.skins.map((skin, index) => (
                  <Thumbnail
                    className={`${styles.thumbnail} ${activeSkinIndex === index ? styles.isActive : ""}`}
                    key={skin.name}
                    thumbnail={skin.thumbnail}
                    index={index}
                    setActiveItem={setActiveSkinIndex}
                  />
                ))}
              </ul>
            )}
            <p className={`copy-01 ${styles.championDescription}`}>{selectedChampion.description}</p>
          </div>
          <ul className={styles.bulletsWrapper}>
            {champions.map((_, index: number) => (
              <li
                key={index}
                className={`${styles.bulletWrapper} ${activeChampionIndex === index ? styles.active : ""}`}
              >
                <Icon className={styles.bulletIcon} name="Diamond" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
